/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import { Layout, PageBuilder, SEO } from "~components";
import * as PageBuilderSections from "~components/PageBuilder/sections";
import { useApp } from "~hooks/useApp.jsx";

/** ============================================================================
 * @component
 * Buildable page template for Sanity data sources.
 */
const Page = ({ data, location }) => {
  const content = data?.sanityPage || {};
  const globals = data?.sanityGlobals;

  const { setContactEmail, setPhoneNumber, setAddress, setSocialLink } =
    useApp();
  setContactEmail(globals?.contactEmail);
  setPhoneNumber(globals?.phoneNumber);
  setAddress(globals?.address);
  setSocialLink(globals?.socialLink);

  let pageBuilderContent = {};

  if (content?.pagebuilder) {
    pageBuilderContent = content.pagebuilder;
  }

  //

  return (
    <>
      <SEO
        customTitle={content?.title}
        customDescription={content?.seoDescription}
        customKeywords={content?.seoKeywords}
        path={location.pathname}
      />
      <Layout>
        <PageBuilder
          content={pageBuilderContent}
          sections={PageBuilderSections}
        />
      </Layout>
    </>
  );
};

export default Page;

export const query = graphql`
  query SanityPage($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      seoDescription
      seoKeywords

      ...PageBuilderFragment
    }
    sanityGlobals {
      contactEmail
      phoneNumber
      address
      socialLink
    }
  }
`;
