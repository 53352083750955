/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const TextBlocksSection = ({
  data: { heading, textBlocks, backgroundColor, bannerColor }
}) => (
  <Wrapper
    css={[
      css`
        background-color: ${backgroundColor?.hex};
      `
    ]}
  >
    <div
      css={[
        css`
          width: 100%;
          height: calc(40vh);
          background-color: ${bannerColor.hex};
          color: var(--color-white);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          ${MEDIA_QUERIES?.desktop} {
            height: calc(32vw);
          }
        `
      ]}
    >
      <h2
        css={[
          css`
            width: 48rem;
            max-width: 80vw;
          `
        ]}
        className="h2"
      >
        {heading}
      </h2>
    </div>
    <Grid
      _css={[
        css`
          padding: 4rem 0 0;
          ${MEDIA_QUERIES?.desktop} {
            padding: 6rem 0 0;
          }
        `
      ]}
    >
      <ul
        css={[
          css`
            grid-column: span 12 / span 12;
          `
        ]}
      >
        {textBlocks.map((textBlock) => (
          <li
            key={textBlock.heading}
            css={[
              css`
                padding: 0 0 4rem;
                ${MEDIA_QUERIES?.desktop} {
                  padding: 0 0 6rem;
                }
              `
            ]}
          >
            <h2
              css={[
                css`
                  padding: 0 0 2rem;
                `
              ]}
              className="h2"
            >
              {textBlock.heading}
            </h2>
            <p
              css={[
                css`
                  white-space: pre-wrap;
                `
              ]}
              className="b1"
            >
              {textBlock.body}
            </p>
          </li>
        ))}
      </ul>
    </Grid>
  </Wrapper>
);

export const query = graphql`
  fragment TextBlocksSectionFragment on SanityTextBlocksSection {
    _type

    backgroundColor {
      hex
      title
    }

    bannerColor {
      hex
      title
    }
    heading

    textBlocks {
      heading
      body
      icon {
        altText

        asset {
          originalFilename
          title
          description

          gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

export default TextBlocksSection;
