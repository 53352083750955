/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image } from "~components";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const Figure = styled.figure`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const ImageCSS = css`
  width: 100%;
  position: relative;
  display: block;
`;

/** ============================================================================
 * @component
 * Sample component receiving a full-width PageBuilder image.
 */
const ImageSection = ({ data: { image } }) => (
  <Wrapper>
    <Figure>
      <Image _css={ImageCSS} image={image} />
    </Figure>
  </Wrapper>
);

/** ============================================================================
 * graphql query
 */
export const query = graphql`
  fragment ImageSectionFragment on SanityImageSection {
    _type

    backgroundColor {
      hex
      title
    }

    image {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default ImageSection;
