/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Image, Go, Button } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { useWindowDimensions } from "~hooks";

const Wrapper = styled.section`
  width: 100%;
  position: relative;

  overflow-x: hidden;
`;

const Dot = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: var(--color-light-aqua);
  display: inline-block;
`;

const ImageArticleSection = ({
  data: {
    heading,
    subHeading,
    body,
    alignment,
    button,
    icon,
    images,
    hasBottomPadding,
    backgroundColor,
    services
  }
}) => {
  const { isDesktop } = useWindowDimensions();

  return (
    <Wrapper
      css={[
        css`
          background-color: ${backgroundColor?.hex};
          padding: 4rem 0 0;
          ${MEDIA_QUERIES?.desktop} {
            padding: 6rem 0 ${hasBottomPadding ? `6rem` : `0`};
          }
        `
      ]}
    >
      {!isDesktop && (
        <Image
          _css={[
            css`
              min-height: 28rem;
              margin: 0 0 4rem 0;
            `
          ]}
          image={images?.[0]?.asset}
        />
      )}
      <Grid>
        <article
          css={[
            css`
            grid-column: span 12/ span 12;
            grid-column-start: 1
            text-align: left;
            ${MEDIA_QUERIES?.desktop}{
              grid-column: span 5 / span 5;
              grid-column-start: ${alignment === `left` ? 1 : 8};
            }
            ${MEDIA_QUERIES?.large}{
              ${alignment === `right` && `padding-left: 2rem`};
              ${alignment === `left` && `padding-right: 2rem`};
            }
          `
          ]}
        >
          <Image
            _css={[
              css`
                width: 60px;
                ${MEDIA_QUERIES?.desktop} {
                  width: 90px;
                }
              `
            ]}
            image={icon?.asset}
          />
          <header
            css={[
              css`
                padding-top: 3rem;
                padding-bottom: 3rem;
              `
            ]}
          >
            <h4
              css={[
                css`
                  padding-bottom: 1.5rem;
                  color: var(--color-light-aqua);
                `
              ]}
              className="h4"
            >
              {heading}
            </h4>
            <h2
              css={[
                css`
                  width: 80%;
                `
              ]}
              className="h2"
            >
              {subHeading}
            </h2>
          </header>
          <p className="b1">{body}</p>
          <ul
            css={[
              css`
                padding: 2rem 0 3rem;
                ${MEDIA_QUERIES?.desktop} {
                  padding: 2rem 0 3rem;
                }
              `
            ]}
          >
            {services?.map((service, index) => (
              <>
                <li
                  css={css`
                    display: inline;
                  `}
                >
                  <span className="b1">{service}</span>
                  {index < services.length - 1 && (
                    <Dot
                      css={css`
                        margin: 3px 8px;
                      `}
                    />
                  )}
                </li>
              </>
            ))}
          </ul>
          <div
            css={css`
              padding: 0rem 0 3rem;
              ${MEDIA_QUERIES?.desktop} {
                padding: 0rem 0 0rem;
              }
            `}
          >
            <Go to={button?.url} newTab>
              <Button text={button.text} />
            </Go>
          </div>
        </article>
        {isDesktop && (
          <Image
            _css={[
              css`
                height: 100%;
                grid-column: span 7 / span 7;
                grid-column-start: ${alignment === `left` ? 6 : 1};
                grid-row-start: 1;
              `
            ]}
            image={images?.[0]?.asset}
          />
        )}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ImageArticleSectionFragment on SanityImageArticleSection {
    _type

    backgroundColor {
      hex
      title
    }
    heading
    subHeading
    body
    alignment
    services

    button {
      text
      url
    }

    icon {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }

    images {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }

    hasBottomPadding
  }
`;

export default ImageArticleSection;
