/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, SectionHeading } from "~components";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const SectionTemplate = ({ data: { heading, body, backgroundColor } }) => (
  <Wrapper
    css={[
      css`
        background-color: ${backgroundColor?.hex};
      `
    ]}
  >
    <Grid>
      <SectionHeading heading={heading} body={body} />
    </Grid>
  </Wrapper>
);

// export const query = graphql`
//   fragment SectionTemplateFragment on SectionTemplateSection {
//     _type

//     backgroundColor {
//       hex
//       title
//     }
//     heading
//     body
//   }
// `;

export default SectionTemplate;
