/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, SectionHeading, Button, Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const Heading = styled.h2``;

const ImageTextCenteredSection = ({
  data: {
    heading,
    subHeading,
    body,
    backgroundColor,
    boxColor,
    button,
    imagePosition,
    image
  }
}) => (
  <Wrapper
    css={[
      css`
        background-color: ${backgroundColor?.hex};
      `
    ]}
  >
    {imagePosition === `top` && (
      <Image
        image={image?.asset}
        _css={[
          css`
            object-fit: cover;
            width: 100%;
            min-height: 22rem;
          `
        ]}
      />
    )}

    <article
      css={[
        imagePosition === `top` &&
          css`
            margin-top: -4rem;
            ${MEDIA_QUERIES?.desktop} {
              margin-top: -11rem;
            }
          `
      ]}
    >
      <Grid
        _css={[
          css`
            background-color: ${boxColor?.hex};
            z-index: 1;
            padding: 4rem 0;
            ${MEDIA_QUERIES?.desktop} {
              padding: 6rem 0;
            }
          `
        ]}
      >
        <SectionHeading heading={heading} body={subHeading} />
        <p
          css={[
            css`
              grid-column: span 10 / span 10;
              grid-column-start: 2;
              text-align: center;
              ${MEDIA_QUERIES?.desktop} {
                grid-column: span 6 / span 6;
                grid-column-start: 4;
              }
            `
          ]}
          className="b1"
        >
          {body}
        </p>
        <div
          css={[
            css`
              grid-column: span 12 / span 12;
              text-align: center;
              padding: 3rem 0 0;
              ${MEDIA_QUERIES?.desktop} {
                padding: 6rem 0 0;
              }
            `
          ]}
        >
          <Go to={button?.url}>
            <Button text={button?.text} _css={[css``]} />
          </Go>
        </div>
      </Grid>
    </article>

    {imagePosition === `bottom` && (
      <Image
        image={image?.asset}
        _css={[
          css`
            object-fit: cover;
            width: 100%;
            min-height: 14rem;
            margin-top: -10rem;
            ${MEDIA_QUERIES?.desktop} {
              margin-top: -11rem;
            }
          `
        ]}
      />
    )}
  </Wrapper>
);

export const query = graphql`
  fragment ImageTextCenteredSectionFragment on SanityImageTextCenteredSection {
    _type

    backgroundColor {
      hex
      title
    }

    boxColor {
      hex
      title
    }

    heading
    subHeading
    body

    imagePosition

    image {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
      }
    }

    button {
      text
      url
    }
  }
`;

export default ImageTextCenteredSection;
