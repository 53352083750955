/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useRef } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useCaseStudies, useWindowDimensions } from "~hooks";
import {
  Grid,
  SectionHeading,
  Button,
  SwiperCarousel,
  Go,
  CaseStudyBlock
} from "~components";
import { MEDIA_QUERIES } from "~utils/css";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const LinkCarouselSection = ({
  data: { heading, body, backgroundColor, button }
}) => {
  const caseStudies = useCaseStudies();
  const { isDesktop } = useWindowDimensions();

  let swiperNext = useRef();
  let swiperPrev = useRef();
  const injectNav = (nav) => {
    swiperNext = nav.next;
    swiperPrev = nav.prev;
  };

  return (
    <Wrapper
      css={[
        css`
          background-color: ${backgroundColor?.hex};
          overflow-x: hidden;
        `
      ]}
    >
      <Grid
        _css={[
          css`
            padding: 4rem 0;
            ${MEDIA_QUERIES?.desktop} {
              padding: 6rem 0;
            }
          `
        ]}
      >
        <SectionHeading
          heading={heading}
          body={body}
          _css={css`
            padding-bottom: 3rem;
          `}
        />

        {isDesktop ? (
          <div
            css={[
              css`
                grid-column: span 12 / span 12;
              `
            ]}
          >
            <SwiperCarousel
              nav={injectNav}
              slideColumnWidth="4"
              slides={caseStudies.map((study) => (
                <article
                  key={study.title}
                  css={[
                    css`
                      padding-bottom: 100%;
                      z-index: 0;
                    `
                  ]}
                >
                  <CaseStudyBlock
                    name={study?.title}
                    client={study?.client}
                    image={study?.cardImage?.asset}
                    type={study?.category}
                    link={`/our-work/${study?.slug?.current}`}
                    details={study?.details}
                  />
                </article>
              ))}
            />
          </div>
        ) : (
          caseStudies.slice(0, 3).map((study) => (
            <article
              key={study.title}
              css={[
                css`
                  position: relative;
                  grid-column: span 12 / span 12;
                  padding-bottom: 100%;
                  margin: 0 0 2rem;
                  z-index: 0;
                `
              ]}
            >
              <CaseStudyBlock
                name={study?.title}
                client={study?.client}
                image={study?.cardImage?.asset}
                type={study?.category}
                link={`/our-work/${study?.slug?.current}`}
                details={study?.details}
              />
            </article>
          ))
        )}

        {isDesktop && (
          <span
            css={[
              css`
                grid-column: span 2 / span 2;
                grid-column-start: 6;
                text-align: center;
                justify-content: center;
                gap: 1rem;
                display: flex;
                padding: 12px 0 0 0;
                color: white;
              `
            ]}
          >
            <button
              type="button"
              aria-label="swiper previous"
              onClick={() => swiperPrev()}
              className="h3"
              css={css`
                width: 2.1rem;
                height: 2.1rem;
                background: var(--color-light-aqua);
                border-radius: 50%;
              `}
            >
              &lt;
            </button>
            <button
              type="button"
              aria-label="swiper next"
              onClick={() => swiperNext()}
              className="h3"
              css={css`
                width: 2.1rem;
                height: 2.1rem;
                background: var(--color-light-aqua);
                border-radius: 50%;
              `}
            >
              &gt;
            </button>
          </span>
        )}

        {button && (
          <div
            css={[
              css`
                grid-column: span 12 / span 12;
                text-align: center;
                padding: 2rem 0 0;
                ${MEDIA_QUERIES?.desktop} {
                  padding: 6rem 0 0;
                }
              `
            ]}
          >
            <Go to={button?.url}>
              <Button text={button?.text} _css={[css``]} />
            </Go>
          </div>
        )}
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment LinkCarouselSectionFragment on SanityLinkCarouselSection {
    _type

    backgroundColor {
      hex
      title
    }
    heading
    body

    button {
      text
      url
    }
  }
`;

export default LinkCarouselSection;
