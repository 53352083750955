/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Map, Go } from "~components";
import { useApp } from "~hooks/useApp.jsx";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Phone } from "~assets/icons/phone.svg";
import { ReactComponent as Mail } from "~assets/icons/mail.svg";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
`;

const ContactSection = ({
  data: { heading, backgroundColor, bannerColor }
}) => {
  const { contactEmail, phoneNumber, address } = useApp();

  return (
    <Wrapper
      css={[
        css`
          background-color: ${backgroundColor?.hex};
        `
      ]}
    >
      <div
        css={[
          css`
            width: 100%;
            height: calc(100vh - 4rem);
            background-color: ${bannerColor.hex};
            color: var(--color-white);
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            ${MEDIA_QUERIES?.desktop} {
              height: calc(56.25vw);
            }
          `
        ]}
      >
        <header
          css={[
            css`
              display: flex;
              flex-direction: column;
              align-items: center;
              position: absolute;
              padding: 0;
              ${MEDIA_QUERIES?.desktop} {
                padding: 0 0 4rem;
              }
            `
          ]}
        >
          <h2
            css={[
              css`
                width: 80vw;
                max-width: 32rem;
                white-space: pre-wrap;
                padding: 0 0 4rem;
                ${MEDIA_QUERIES?.desktop} {
                  width: 48rem;
                }
              `
            ]}
            className="h2"
          >
            {heading}
          </h2>

          <span
            css={[
              css`
                display: flex;
                gap: 1.5rem;
                flex-direction: column;
                ${MEDIA_QUERIES?.desktop} {
                  gap: 4rem;
                  flex-direction: row;
                }
              `
            ]}
          >
            <Go
              to={`mailto:${contactEmail}`}
              _css={[
                css`
                  display: flex;
                  gap: 1.5rem;
                  justify-items: center;
                  align-content: center;
                  align-items: center;
                `
              ]}
            >
              <Mail />

              <p className="h6">{contactEmail}</p>
            </Go>
            <Go
              to={`tel:${phoneNumber}`}
              _css={[
                css`
                  display: flex;
                  gap: 1.5rem;
                  justify-items: center;
                  align-content: center;
                  align-items: center;
                `
              ]}
            >
              <Phone />
              <p className="h6">{phoneNumber}</p>
            </Go>
          </span>

          <span
            css={[
              css`
                padding: 1rem 0 0;
                width: 18rem;
                ${MEDIA_QUERIES?.desktop} {
                  padding: 2.5rem 0 0;
                  width: 24rem;
                }
              `
            ]}
          >
            <h3
              className="h6"
              css={css`
                color: var(--color-light-aqua);
                padding-bottom: 0.5rem;
              `}
            >
              Address
            </h3>
            <p className="h6"> {address}</p>
          </span>
        </header>
      </div>

      <div
        css={[
          css`
            margin-top: -15vw;
          `
        ]}
      >
        <Grid
          _css={[
            css`
              padding: 0 0 4rem;
              ${MEDIA_QUERIES?.desktop} {
                padding: 0 0 6rem;
              }
            `
          ]}
        >
          <figure
            css={[
              css`
                grid-column: span 12 / span 12;
                background-color: grey;
                width: 100%;
                height: 80vh;

                ${MEDIA_QUERIES?.desktop} {
                  height: 45vw;
                }
              `
            ]}
          >
            <Map />
          </figure>
        </Grid>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ContactSectionFragment on SanityContactSection {
    _type

    backgroundColor {
      hex
      title
    }

    bannerColor {
      hex
      title
    }

    heading
  }
`;

export default ContactSection;
