/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, CaseStudyBlock, Button } from "~components";
import { useCaseStudies } from "~hooks";
import { MEDIA_QUERIES } from "~utils/css";
import { GRID_GAP_REM } from "~components/_common/Grid/index.jsx";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  padding: 4rem 0 4rem;
  ${MEDIA_QUERIES?.desktop} {
    padding: 6rem 0 4rem;
  }
`;

const categories = [`All projects`, `Strategy`, `Execution`, `Capability`];

const CaseStudiesSection = ({ data: { backgroundColor } }) => {
  const caseStudies = useCaseStudies();
  const [studyNumber, SetStudyNumber] = useState(4);
  const [studyFilter, SetStudyFilter] = useState(`all projects`);

  return (
    <Wrapper
      css={[
        css`
          background-color: ${backgroundColor?.hex};
        `
      ]}
    >
      <Grid
        _css={css`
          padding-left: 0;
          padding-right: 0;
          ${MEDIA_QUERIES?.desktop} {
            padding-left: 2rem;
            padding-right: 2rem;
          }
        `}
      >
        <ul
          css={[
            css`
              grid-column: span 12 / span 12;
              display: flex;
              justify-content: center;
              gap: 1.5rem;
              padding: 0 0 4rem;
              flex-direction: column;
              ${MEDIA_QUERIES?.desktop} {
                flex-direction: row;
                padding: 0 0 8rem;
                gap: 8rem;
              }
            `
          ]}
        >
          {categories.map((category) => (
            <li
              css={css`
                text-align: center;
              `}
            >
              <button
                key={category}
                type="button"
                css={[
                  css`
                    color: ${category.toLowerCase() === studyFilter
                      ? `var(--color-light-aqua)`
                      : `var(--color-black)`};

                    &:hover {
                      color: var(--color-light-aqua);
                    }
                  `
                ]}
                onClick={() => {
                  SetStudyFilter(category.toLowerCase());
                  SetStudyNumber(4);
                }}
              >
                <p
                  css={css`
                    font-weight: ${category.toLowerCase() === studyFilter
                      ? 400
                      : 300};
                  `}
                  className="hLight"
                >
                  {category}
                </p>
              </button>
            </li>
          ))}
        </ul>

        {caseStudies
          .filter(
            (value) =>
              value?.category === studyFilter || studyFilter === `all projects`
          )
          .slice(0, studyNumber)
          .map((study) => (
            <article
              key={study?.title}
              css={[
                css`
                  position: relative;
                  grid-column: span 12 / span 12;
                  padding-bottom: 100%;
                  margin-bottom: ${GRID_GAP_REM}rem;
                  ${MEDIA_QUERIES?.desktop} {
                    grid-column: span 6 / span 6;
                  }
                  z-index: 0;
                `
              ]}
            >
              <CaseStudyBlock
                name={study?.title}
                client={study?.client}
                image={study?.cardImage?.asset}
                type={study?.category}
                link={`/our-work/${study?.slug?.current}`}
                details={study?.details}
              />
            </article>
          ))}

        <div
          css={[
            css`
              grid-column: span 12 / span 12;
              text-align: center;
              padding: 3rem 0 0;
              ${MEDIA_QUERIES?.desktop} {
                padding: 6rem 0 0;
              }
            `
          ]}
        >
          <Button
            text="Load more"
            _css={css`
              visibility: ${studyNumber >=
              caseStudies.filter(
                (value) =>
                  value?.category === studyFilter ||
                  studyFilter === `all projects`
              ).length
                ? `hidden`
                : `visible`};
            `}
            onClick={() => {
              SetStudyNumber(studyNumber + 2);
            }}
          />
        </div>
      </Grid>
    </Wrapper>
  );
};

export const query = graphql`
  fragment CaseStudiesSectionFragment on SanityCaseStudiesSection {
    _type

    backgroundColor {
      hex
      title
    }
  }
`;

export default CaseStudiesSection;
